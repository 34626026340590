.header {
  padding: 5px 20px;
}

.menuItem {
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    border-radius: 0.5rem;
  }
}

.menuItemMobile {
  @extend .menuItem;
  padding: 5px 5px;
}
