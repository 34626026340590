.container {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  z-index: 3;
}

.languageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: rgba(189, 197, 209, 0.2);
  }
}

.title {
  padding-bottom: 10px;
  color: #8c98a4;
  font-weight: 600;
  font-size: 0.65625rem;
}
